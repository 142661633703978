import dayjs from "dayjs";

export default function ShopRow({ shop: { id, shop_name, code, email, type, status, approval_status, created_at, subscription_until }, onSelect }) {
  return <>
    <tr className="cursor-pointer hover:bg-gray-200" onClick={onSelect}>
      <th className="px-6 py-2">{id}</th>
      <td className="px-6 py-2">{shop_name}</td>
      <td className="px-6 py-2">{code}</td>
      <td className="px-6 py-2">{email}</td>
      <td className="px-6 py-2 capitalize">{type}</td>
      <td className="px-6 py-2">{status}</td>
      <td className="px-6 py-2">{approval_status}</td>
      <td className="px-6 py-2">{dayjs(created_at).format("YYYY-MM-DD hh:mm:ss")}</td>
      <td className="px-6 py-2">{subscription_until}</td>
    </tr>
  </>;
}
