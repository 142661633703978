import React from "react";

import RequestRow from "./RequestRow.jsx";

export default function RequestTable(props) {
  const { requests } = props;
  console.log(requests);
  return (
    <>
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
          <tr>
            <th className="px-6 py-2">Request Date</th>
            <th className="px-6 py-2">Request ID</th>
            <th className="px-6 py-2">Request Amount</th>
            <th className="px-6 py-2">Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {requests.map((request) => (
            <RequestRow key={request.id} request={request} />
          ))}
        </tbody>
      </table>
    </>
  );
}
