import { useHistory } from "react-router-dom";

import useAPIData from "hooks/useAPIData";
import ShopsTable from "./Shops/ShopsTable.jsx";

export default function Shops() {
  const history = useHistory();
  const { data: shops = [] } = useAPIData("/shops");

  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">Shops</h1>
      <ShopsTable
        shops={shops}
        onSelect={(shop) => history.push(`shop/${shop.id}`)}
      />
    </>
  );
}
