import React from "react";
import _ from "lodash";
import useAPI from "hooks/useAPI";
import useAlertWrapper from "hooks/useAlertWrapper";

export default function EditModal(props) {
  const { request } = useAPI();
  const saveRequest = useAlertWrapper(request, {
    success: { message: "Updated" },
    pending: { message: "Saving..." },
    error: { message: "Error while saving" },
  });
  const { onEdit, editFields, setEditFields, shopId, onUpdated } = props;
  let editInputs = _.map(editFields, (value, key) => {
    return (
      <div key={key} className="w-full px-3 mb-6 mt-4 content-center">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {key}
        </label>
        <input
          className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
          type="text"
          name="minimum_payout"
          value={value}
          onChange={(e) => {
            setEditFields({ ...editFields, ...{ [key]: e.target.value } });
          }}
          required
        />
      </div>
    );
  });
  const save = async () => {
    await saveRequest({
      method: "post",
      url: `/shops/${shopId}/documents/update`,
      data: editFields,
    });
    onEdit(false);
    await onUpdated();
  };
  return (
    <>
      <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white rounded shadow-lg w-1/3">
          <div className="border-b px-4 py-2 flex justify-between items-center">
            <h3 className="font-semibold text-lg">Edit</h3>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap mx-auto mb-6 justify-center">
              {editInputs}
            </div>
          </div>
          <div className="flex justify-end items-center w-100 border-t p-3">
            <button
              onClick={() => {
                onEdit(false);
              }}
              className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal"
            >
              Cancel
            </button>
            <button
              onClick={save}
              className="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
