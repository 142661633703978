import { Link, matchPath, useLocation } from "react-router-dom";

export default function SidebarLink({ to, icon, name }) {
  const location = useLocation();
  const match = matchPath(location.pathname, { path: to, exact: true, strict: true }) !== null
  return <>
    <li>
      <Link
        className={
          "block py-3 text-xs uppercase font-bold cursor-pointer " +
          (match ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600")
        }
        to={to}
      >
        <i className={`${icon} fa-fw mr-2 text-sm ` + (match ? "opacity-75" : "text-gray-400")}></i>
        {` ${name}`}
      </Link>
    </li>
  </>;
}
