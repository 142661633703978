import { useRef } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { useMutableObject } from "common/hooks.js";
import useAPI from "hooks/useAPI";
import useAPIData from "hooks/useAPIData";
import useAlertWrapper from "hooks/useAlertWrapper";
import Button from "components/Buttons/Button.jsx";
import RequestTable from "./Transfer/RequestTable.jsx";

export default function Transfer() {
  const transferredAtRef = useRef("");
  const { id } = useParams();

  const { data } = useAPIData(`/payout/transfers/${id}`);
  const {
    data: transfer,
    update: updateTransfer,
    dirty,
    commit,
  } = useMutableObject(data);
  const { request } = useAPI();
  const alertRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Error while saving transfer data" },
  });

  const submit = async (ev) => {
    ev.preventDefault();
    await alertRequest({
      method: "post",
      url: `payout/transfers/${id}/update`,
      data: {
        status: transfer.status,
        bank_reference: transfer.bank_reference,
        transferred_at: dayjs(transferredAtRef.current.value).format(
          "YYYY-MM-DD [00:00:00]"
        ),
        remark: transfer.remark,
      },
    });
    commit();
  };
  const transferedAt = dayjs().format("YYYY-MM-DD");
  return data === undefined ? null : (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        Transfer #{id}
      </h1>
      <div className="mb-6">
        <RequestTable requests={data.requests.requests} />
      </div>
      <div
        className="grid grid-cols-12 gap-x-4 gap-y-2"
        style={{ gridTemplateColumns: "max-content max-content" }}
      >
        <span className="text-right font-semibold">Total Sales</span>
        <span>HKD {parseFloat(transfer.balance.sales_amount).toFixed(2)}</span>
        <span className="text-right font-semibold">Total Payouts</span>
        <span>
          HKD {parseFloat(transfer.balance.transferred_balance).toFixed(2)}
        </span>
        <span className="text-right font-semibold">Total Reserve</span>
        <span>
          HKD {parseFloat(transfer.balance.rolling_reserve_amount).toFixed(2)}
        </span>
        <span className="text-right font-semibold">Total Refund</span>
        <span>
          HKD {Math.abs(parseFloat(transfer.balance.refund_amount)).toFixed(2)}
        </span>

        <span className="text-right font-semibold mt-4">Transfer ID</span>
        <span className="mt-4">{transfer.id}</span>
        <span className="text-right font-semibold">Transfer Time</span>
        <span>{transfer.created_at}</span>
        <span className="text-right font-semibold">Shop ID</span>
        <span>{transfer.shop_id}</span>
        <span className="text-right font-semibold">Currnecy</span>
        <span>{transfer.currency}</span>
        <span className="text-right font-semibold">Amount</span>
        <span>{parseFloat(transfer.amount).toFixed(2)}</span>

        <span className="text-right font-semibold mt-4">Seller's Email</span>
        <span className="mt-4">{transfer.seller.email}</span>
        <span className="text-right font-semibold">Account Type</span>
        <span>{transfer.seller.type}</span>
        <span className="text-right font-semibold">Account Status</span>
        <span>{transfer.seller.shop_status}</span>
        <span className="text-right font-semibold">Balance Status</span>
        <span>{transfer.seller.balance_status}</span>

        <span className="text-right font-semibold mt-4">Bank Name</span>
        <span className="mt-4">{transfer.bank.bank_name}</span>
        <span className="text-right font-semibold">Bank Code</span>
        <span>{transfer.bank.bank_code}</span>
        <span className="text-right font-semibold">Account Number</span>
        <span>{transfer.bank.account_number}</span>
        <span className="text-right font-semibold">Account Holder Name</span>
        <span>{transfer.bank.account_holder}</span>
      </div>

      <form>
        <fieldset
          className="my-8 grid gap-x-4 gap-y-2"
          style={{ gridTemplateColumns: "max-content 1fr" }}
        >
          <label className="block py-1 text-right font-semibold text-gray-800">
            Status
          </label>
          <select
            value={transfer.status}
            onChange={(ev) => {
              updateTransfer("status", ev.target.value);
            }}
            className="border px-1 border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30"
          >
            <option value="PENDING">PENDING</option>
            <option value="COMPLETED">COMPLETED</option>
          </select>

          <label className="block py-1 text-right font-semibold text-gray-800">
            Bank Reference
          </label>
          <input
            type="text"
            value={transfer.bank_reference}
            onChange={(ev) => {
              updateTransfer("bank_reference", ev.target.value);
            }}
            className="border px-2 border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30 disabled:bg-gray-200 disabled:cursor-not-allowed"
            disabled={transfer.status === "PENDING"}
          />

          <label className="block py-1 text-right font-semibold text-gray-800">
            Transfer Date
          </label>
          <input
            type="text"
            value={
              dirty.transferred_at
                ? dayjs(transfer.transferred_at).format("YYYY-MM-DD")
                : transferedAt
            }
            onChange={(ev) => {
              updateTransfer("transferred_at", ev.target.value);
            }}
            ref={transferredAtRef}
            className="border px-2 border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30 disabled:bg-gray-200 disabled:cursor-not-allowed"
            disabled={transfer.status === "PENDING"}
          />

          <label className="block py-1 text-right font-semibold text-gray-800">
            Remarks
          </label>
          <textarea
            value={transfer.remark}
            onChange={(ev) => {
              updateTransfer("remark", ev.target.value);
            }}
            className="border px-2 py-1 border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30 disabled:bg-gray-200 disabled:cursor-not-allowed"
          ></textarea>
        </fieldset>

        <div className="text-right">
          <Button
            type="submit"
            className="bg-blue-500 text-gray-100 ring-blue-500"
            onClick={submit}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
