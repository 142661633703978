import React from "react";

export default function ProductInfo(props) {
  const { product } = props;
  const translation = product.translations[0];
  return (
    <>
      <div className="mx-auto py-4">
        <div className="flex flex-row flex-wrap -mx-2">
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">Name</div>
          <div className="w-full sm:w-5/6 mb-4 px-2">{translation.name}</div>
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
            Short Description
          </div>
          <div className="w-full sm:w-5/6 mb-4 px-2">
            {translation.short_description}
          </div>
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
            Description
          </div>
          <div className="w-full sm:w-5/6 mb-4 px-2">
            {translation.description}
          </div>
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
            Product Type
          </div>
          <div className="w-full sm:w-5/6 mb-4 px-2">{product.type}</div>
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
            Serial No.
          </div>
          <div className="w-full sm:w-5/6 mb-4 px-2">
            {product.serial_number}
          </div>
          <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">SKU</div>
          <div className="w-full sm:w-5/6 mb-4 px-2">{product.sku}</div>
          {product.type === "SIMPLE" ? (
            <>
              <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
                Price
              </div>
              <div className="w-full sm:w-5/6 mb-4 px-2">{`${product.product_prices[0].currency} ${product.product_prices[0].price}`}</div>
            </>
          ) : null}
        </div>
      </div>
      <div className="mx-auto py-4">
        <h1 className="mb-4 font-semibold">Images</h1>
        <div className="flex flex-row flex-wrap -mx-2">
          {product.images.map((image, index) => {
            return (
              <div
                key={`img-${index}`}
                className="w-full sm:w-1/4 h-32 md:h-48 mb-4 px-2"
              >
                <div
                  className="block w-full h-full bg-grey-dark bg-no-repeat bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_STOREFRONT}/${image.image_path})`,
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
