import { useCallback, useContext, useState } from 'react';

import { APIError, Unauthenticated, Canceled } from 'common/api/client';
import { APIContext } from 'contexts/APIContext';
import useAPIAuth from 'hooks/useAPIAuth';

const useAPI = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(null);

    const client = useContext(APIContext);
    const { logout } = useAPIAuth();

    const request = useCallback(async (options) => {
        try {
            setLoading(true);
            const data = await client.request(options);
            setData(data);
            setError(null);
        } catch(err) {
            if (err instanceof Canceled) { return; }

            setError(err);

            if (err instanceof Unauthenticated) {
                logout();
                return;
            }
            if (err instanceof APIError) {
                // API error
            }
            throw err;
        } finally {
            setLoading(false);
        }
    }, [client, logout]);

    return { request, data, loading, error };
}

export default useAPI;