import ShopRow from "./ShopRow.jsx";

export default function ShopsTable({ shops, onSelect }) {
  return <>
    <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
      <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
        <tr>
          <th className="px-6 py-2">Shop ID</th>
          <th className="px-6 py-2">Shop Name</th>
          <th className="px-6 py-2">Shop Code</th>
          <th className="px-6 py-2">Email</th>
          <th className="px-6 py-2">Type</th>
          <th className="px-6 py-2">Shop Status</th>
          <th className="px-6 py-2">Approval Status</th>
          <th className="px-6 py-2">Created At</th>
          <th className="px-6 py-2">Expires At</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {shops.map((shop) => <ShopRow key={shop.id} shop={shop} onSelect={() => onSelect(shop)} />)}
      </tbody>
    </table>
  </>;
}
