import axios, { CancelToken, isCancel } from 'axios';

export class APIError extends Error {
    constructor(code, message) {
        super(message);
        this.code = code;
    }
}
export class Canceled extends Error {}
export class Unauthenticated extends APIError {}

export class Client {
    token = null;
    agent;

    constructor(baseURL = process.env.REACT_APP_API_ENDPOINT) {
        this.agent = axios.create({ baseURL });
        this.agent.interceptors.request.use((request) => {
            request.headers['x-access-token'] = this.token;
            return request;
        });
    }

    login(token) {
        this.token = token;
    }
    logout() {
        this.token = null;
    }

    request(options) {
        const source = CancelToken.source();
        const promise = this.agent({ cancelToken: source.token, ...options })
            .catch((err) => {
                if (isCancel(err)) { throw Canceled(err.message); }
                if (err.response) {
                    const { code, msg } = err.response.data;
                    if (code !== undefined && msg !== undefined) {
                        throw (code === 403 ? new Unauthenticated(code, msg) : new APIError(code, msg));
                    }
                }
                throw err;
            })
            .then((response) => {
                if(options.responseType === "blob"){
                    return response;
                }
                const { success, code, msg, data } = response.data;
                if (success === false) {
                    throw new APIError(code, msg);
                }
                return data;
            });

        return Object.assign(promise, {
            cancel: (message) => { source.cancel(message) }
        });
    }
    
    get(url, options) {
        return this.request({ url, method: 'get', ...options })
    }
    post(url, options) {
        return this.request({ url, method: 'post', ...options })
    }
}

class DefaultClient extends Client {
    constructor(baseURL) {
        super(baseURL);
        this.login(localStorage.getItem('accessToken'));
    }

    login(token) {
        super.login(token)
        localStorage.setItem('accessToken', token);
    }
    logout() {
        super.logout()
        localStorage.removeItem('accessToken');
    }
}

export default new DefaultClient();
