import React from "react";

export default function RequestRow(props) {
  const { id, amount, created_at, currency } = props.request;
  console.log(props);
  return (
    <tr className="cursor-pointer hover:bg-gray-200">
      <th className="px-6 py-2">{created_at}</th>
      <td className="px-6 py-2">{id}</td>
      <td className="px-6 py-2">
        {currency} {amount}
      </td>
      <td className="px-6 py-2">APPROVED</td>
    </tr>
  );
}
