import { Link } from "react-router-dom";

import useAPIAuth from "hooks/useAPIAuth";
import SidebarLink from "./SidebarLink.jsx";

export default function Sidebar() {
  const { logout } = useAPIAuth();

  return (
    <>
      <nav className="fixed left-0 top-0 bottom-0 w-64 z-10 block overflow-x-hidden overflow-y-auto p-6 bg-white shadow-xl">
        <div className="w-full min-h-full flex flex-col flex-no-wrap">
          {/* Brand */}
          <h2 className="block text-left tex-lg text-gray-800 whitespace-no-wrap uppercase font-bold">
            <Link to="/admin">Mazetag Shop</Link>
          </h2>

          <hr className="my-4 min-w-full" />

          <ul className="min-w-full flex flex-col list-none">
            <SidebarLink name="Shops" to="/admin/shops" icon="fas fa-store" />
            <SidebarLink
              name="Orders"
              to="/admin/orders"
              icon="fas fa-shopping-bag"
            />
            <SidebarLink
              name="Refunds"
              to="/admin/refunds"
              icon="fas fa-exchange-alt"
            />
            <SidebarLink
              name="Products"
              to="/admin/products"
              icon="fas fa-boxes"
            />
          </ul>

          {/* <hr className="my-4 min-w-full" />

          <ul className="min-w-full flex flex-col list-none">
            <li className="my-px">
              <span className="flex font-medium text-sm text-gray-400 px-4 my-4 uppercase">
                PAYOUTS
              </span>
            </li>
            <SidebarLink
              name="Validate Requests"
              to="/admin/payouts/requests"
              icon="fas fa-dollar-sign"
            />
          </ul>

          <hr className="my-4 min-w-full" />
          <ul className="min-w-full flex flex-col list-none">
            <li className="my-px">
              <span className="flex font-medium text-sm text-gray-400 px-4 my-4 uppercase">
                TRANSFERS
              </span>
            </li>
            <SidebarLink
              name="Transfer List"
              to="/admin/payouts/transfers"
              icon="fas fa-hand-holding-usd"
            />
          </ul> */}

          <hr className="my-4 min-w-full" />

          <ul className="min-w-full flex flex-col list-none">
            <li
              onClick={() => {
                logout();
              }}
            >
              <span className="block py-3 text-xs uppercase font-bold cursor-pointer text-gray-800 hover:text-gray-600">
                <i className="fas fa-sign-out-alt fa-fw mr-2 text-sm text-gray-400"></i>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
