export default function TransferRow({ transfer: { id, created_at, shop_id, bank_reference, currency, amount, status }, onSelect }) {
  return <>
    <tr className="cursor-pointer hover:bg-gray-200" onClick={onSelect}>
      <th className="px-6 py-2">{id}</th>
      <td className="px-6 py-2">{created_at}</td>
      <td className="px-6 py-2">{shop_id}</td>
      <td className="px-6 py-2">{bank_reference}</td>
      <td className="px-6 py-2">{currency}</td>
      <td className="px-6 py-2 text-right">{amount}</td>
      <td className="px-6 py-2">{status}</td>
    </tr>
  </>;
}
