import React, { useState, useEffect } from "react";
import PaymentMethod from "./Settings/PaymentMethod.jsx";
import _ from "lodash";
import Button from "components/Buttons/Button.jsx";
import useAPI from "hooks/useAPI";
import useAPIData from "hooks/useAPIData";
import useAlertWrapper from "hooks/useAlertWrapper";

export default function Settings(props) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [shopSetting, setShopSetting] = useState({
    minimum_payout: 0,
    status: "ACTIVE",
  });

  const { request } = useAPI();
  const { data } = useAPIData(`shops/${props.shop.id}/settings`);

  useEffect(() => {
    if (data) {
      setPaymentMethods(data.payment_methods);
      setShopSetting({
        minimum_payout: parseFloat(data.minimum_payout).toFixed(2),
        status: data.status,
      });
    }
  }, [data]);

  const handleChange = (e) =>
    setShopSetting((prevshopSetting) => {
      console.log(prevshopSetting);
      return { ...prevshopSetting, [e.target.name]: e.target.value };
    });

  const onChange = (method) => {
    const index = _.findIndex(paymentMethods, { id: method.id });
    const existMethod = _.find(paymentMethods, { id: method.id });
    setPaymentMethods((prevPaymentMethods) => {
      const updatedMethods = prevPaymentMethods;
      updatedMethods.splice(index, 1, {
        ...existMethod,
        ...method,
      });
      return updatedMethods;
    });
  };

  const submitRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Update failed." },
    success: { message: "Saved" },
  });
  const onSubmit = async (event) => {
    event.preventDefault();
    await submitRequest({
      method: "post",
      url: `/shops/${props.shop.id}/settings/update`,
      data: { ...shopSetting, ...{ payment_methods: paymentMethods } },
    });
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="inputs w-full max-w-2xl p-6 mx-auto">
          <h2 className="text-2xl text-gray-900">Shop Setting</h2>
          <form className="mt-6" onSubmit={onSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-full px-3 mb-6 mt-4">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Minimum Payout
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                  type="number"
                  name="minimum_payout"
                  value={shopSetting.minimum_payout}
                  onChange={handleChange}
                  min="0"
                  step=".01"
                  required
                />
              </div>
              <div className="w-full md:w-full px-3 mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Status
                </label>
                <div className="flex-shrink w-full inline-block relative">
                  <select
                    name="status"
                    className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
                    onChange={handleChange}
                    value={shopSetting.status ?? "ACTIVE"}
                  >
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                    <option value="SUSPENDED">SUSPENDED</option>
                  </select>
                  <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              {paymentMethods.map((method) => {
                return (
                  <PaymentMethod
                    key={method.id}
                    {...props}
                    method={method}
                    onChange={onChange}
                  />
                );
              })}

              <div className="w-full md:w-full px-3 mb-6 text-right">
                <Button className="bg-blue-500 text-gray-100 ring-blue-500">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
