import { useState } from "react";

import ConfirmModal from "components/Modal/Confirm.jsx";

export default function RefundModal({ display, refund, onConfirm, onCancel }) {
  const [remark, setRemark] = useState("");
  return (
    <>
      <ConfirmModal
        title={`${display} refund request`}
        onConfirm={() => onConfirm({ remark })}
        onCancel={onCancel}
      >
        <p>Are you sure you want to {display} this refund request?</p>
        <div
          className="mt-2 grid gap-x-4 gap-y-2"
          style={{ gridTemplateColumns: "max-content max-content" }}
        >
          <span className="text-right font-semibold">Shop ID</span>
          <span>{refund.shop_id}</span>
          <span className="text-right font-semibold">Order Ref.</span>
          <span>{refund.order_reference}</span>
          <span className="text-right font-semibold">Txn ID</span>
          <span>{refund.transaction_id}</span>
          <span className="text-right font-semibold">Currnecy</span>
          <span>{refund.currency}</span>
          <span className="text-right font-semibold">Amount</span>
          <span>{refund.refund_amount.toFixed(2)}</span>
          <span className="text-right font-semibold">Refund Time</span>
          <span>{refund.created_at}</span>
          <span className="text-right font-semibold">Remark</span>
          <span>
            <textarea
              rows="3"
              className="bshadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
          </span>
        </div>
      </ConfirmModal>
    </>
  );
}
