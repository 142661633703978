import React, { useState, useEffect } from "react";
import useAPIData from "hooks/useAPIData";
import Button from "components/Buttons/Button";
import useAPI from "hooks/useAPI";
import useAlertWrapper from "hooks/useAlertWrapper";
import client from "common/api/client";

function downloadBlob(blob, extension = "jpg") {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `bank-doc.${extension}`;
  link.click();
}

export default function BankInfo(props) {
  const { request } = useAPI();
  const [verify, setVerify] = useState("PENDING");
  const [isProofExist, setIsProofExist] = useState(false);
  const { data: bankData } = useAPIData(`shops/${props.shop.id}/bank-info`);
  useEffect(() => {
    if (bankData) {
      setVerify(bankData.verify);
      setIsProofExist(bankData.is_exist);
    }
  }, [bankData]);
  const handleChange = (e) => {
    setVerify(e.target.value);
  };
  const approveRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Update failed." },
    success: { message: "Saved" },
  });
  const handleSave = async () => {
    await approveRequest({
      method: "post",
      url: `/shops/${props.shop.id}/bank-info/update`,
      data: { verify },
    });
  };
  return (
    <>
      {bankData && (
        <>
          <div className="text-right mb-2">
            {isProofExist && (
              <Button
                className="bg-blue-500 text-gray-100 ring-blue-500 mr-2"
                onClick={async () => {
                  const { data } = await client.get(
                    `/shops/${props.shop.id}/bank-info/download`,
                    {
                      responseType: "blob",
                    }
                  );
                  downloadBlob(data, bankData.extension);
                }}
              >
                Download Documents
              </Button>
            )}
          </div>
          <div
            className="grid gap-x-4 gap-y-2"
            style={{ gridTemplateColumns: "max-content max-content" }}
          >
            <span className="text-right font-semibold">Bank Name</span>
            <span>{bankData.bank_name}</span>
            <span className="text-right font-semibold">Bank Code</span>
            <span>{bankData.bank_code}</span>
            <span className="text-right font-semibold">Account Holder</span>
            <span>{bankData.account_holder}</span>
            <span className="text-right font-semibold">Account Number</span>
            <span>{bankData.account_number}</span>
            <span className="text-right font-semibold">Verify Status</span>
            <select
              name="status"
              className="form-select mt-1 block w-full border border-gray-400"
              onChange={handleChange}
              value={verify}
            >
              <option value="PENDING">PENDING</option>
              <option value="APPROVED">APPROVED</option>
              <option value="REJECTED">REJECTED</option>
            </select>
          </div>
          <div className="-mx-4 px-4 pt-2 mt-2 text-right">
            <Button
              onClick={handleSave}
              className="bg-blue-500 text-gray-100 ring-blue-500 mr-2 w-32"
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
}
