import React from "react";
import _ from "lodash";

export default function ProductRow(props) {
  const { product, onClick } = props;
  const { translations } = product;
  let currency = "HKD";
  const getProductPrice = (productPrices) => {
    let maxPrice = 0,
      minPrice = 0;
    _.forEach(productPrices, (productPrice) => {
      if (maxPrice === 0 || productPrice.price >= maxPrice) {
        maxPrice = productPrice.price;
      }
      if (minPrice === 0 || productPrice.price <= minPrice) {
        minPrice = productPrice.price;
      }
    });
    if (maxPrice === minPrice) {
      return maxPrice.toFixed(2);
    }
    return `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}`;
  };
  return (
    <tr
      onClick={onClick}
      className="text-left text-xs whitespace-no-wrap cursor-pointer hover:bg-gray-200"
    >
      <td className="px-6 py-2">{product.shop_code}</td>
      <th className="px-6 py-2">{product.serial_number}</th>
      <td className="px-6 py-2">{translations[0]["name"]}</td>
      <td className="px-6 py-2">{translations[0]["short_description"]}</td>
      <td className="px-6 py-2">{currency}</td>
      <td className="px-6 py-2">{getProductPrice(product.product_prices)}</td>
      <td className="px-6 py-2">{product.status}</td>
    </tr>
  );
}
