import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('accessToken'),
    },
    reducers: {
        login: (state, { payload }) => { 
            state.token = payload;
            localStorage.setItem('accessToken', payload);
        },
        logout: () => ({ token: null }),
    },
});


export const loginUser = (client, email, password) => {
    return async (dispatch, getState) => {
        const { token } = await client.post('/login', { data: { email, password } });
        client.login(token);
        dispatch(slice.actions.login(token));
    }
}

export const logoutUser = (client) => {
    return async (dispatch, getState) => {
        dispatch(slice.actions.logout());
        client.logout();
    }
}

export const selectAuth = ({auth}) => auth;

export const { login, logout } = slice.actions;

export default slice.reducer;