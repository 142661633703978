import { useState } from 'react';
import { produce } from 'immer';

export const useMutable = (base) => {
    const [value, setValue] = useState(null);
    return [value ?? base, setValue, value !== null];
}

export const useMutableObject = (obj) => {
    const [updates, setUpdates] = useState({});
    const [dirty, setDirty] = useState({});

    const update = (key, value) => {
        setDirty({ ...updates, [key]: value });
        setUpdates({ ...updates, [key]: value });
    };
    const commit = () => { setDirty({}); }

    return { data: { ...obj, ...updates }, update, commit, updates, dirty };
}

const has = Object.prototype.hasOwnProperty;
export const useMutableCollection = (collection, key = 'id') => {
    const [updates, setUpdates] = useState({});
    const [dirty, setDirty] = useState({});

    const update = (patches) => {
        if (!Array.isArray(patches)) {
            patches = [patches];
        }
        const producer = produce((updates) => {
            for (const patch of patches) {
                const id = patch[key]
                updates[id] = updates[id] ?? {};
                Object.assign(updates[id], patch);
            }
        });

        setUpdates(producer(updates));
        setDirty(producer(dirty));
    };
    const commit = () => { setDirty({}); }


    return {
        data: collection.map((obj) => has.call(updates, obj[key]) ? { ...obj, ...updates[obj[key]] } : obj),
        update,
        commit,
        updates,
        dirty,
    };
}
