import { useEffect, useRef } from 'react';
import useAPI from 'hooks/useAPI';
import {toast} from 'react-toastify';

const useAPIData = (url) => {
    const { request, data, loading, error } = useAPI();
    const toastId = useRef(null);
    useEffect(() => {
        (async () => {
            toastId.current = toast.info('Loading data...', {
                autoClose: false
            });
            try {
                await request({ method: 'get', url });
                setTimeout(() => toast.dismiss(toastId.current), 500);
            } catch(err) {
                toast.update(toastId.current, {
                    render: `Error while loading data - ${err.message}`,
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                });
            }
        })();
    }, [url, request]);

    return { data, loading, error };
}

export default useAPIData;