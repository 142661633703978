import React, { useEffect, useState } from "react";
import useAPIData from "hooks/useAPIData";
import SpecialPaymentsTable from "./SpecialPayments/SpecialPaymentsTable";
import client from "common/api/client";

export default function SpecialPayments(props) {
  const [specialPayments, setSpecialPayments] = useState([]);
  const { data: specialPaymentsData = [], loading } = useAPIData(
    `/special-payments/${props.shop.id}`
  );
  useEffect(() => {
    setSpecialPayments(specialPaymentsData);
  }, [loading]);
  const updateData = async () => {
    const response = await client.get(`/special-payments/${props.shop.id}`);
    setSpecialPayments(response);
  };
  return (
    <>
      <SpecialPaymentsTable
        {...props}
        isUpdated={updateData}
        specialPayments={specialPayments}
      />
    </>
  );
}
