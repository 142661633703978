import React from "react";

export default function Footer() {
  return (
    <>
      <footer>
        <hr className="mx-4 mb-2 border-gray-300" />
        <div className="mx-8 text-sm text-gray-600 font-semibold text-left">
          Copyright © {new Date().getFullYear()}{" "} Mazetag Shop
        </div>
      </footer>
    </>
  );
}
