import ValidateRow from "./ValidateRow.jsx";

export default function ValidateTable({ dirty, requests, onChange }) {
  return <>
    <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
      <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
        <tr>
          <th className="px-6 py-2">Request ID</th>
          <th className="px-6 py-2">Shop ID</th>
          <th className="px-6 py-2">Request Time</th>
          <th className="px-6 py-2">Currnecy</th>
          <th className="px-6 py-2 text-right">Amount</th>
          <th className="px-6 py-2">Status</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {requests.map((request) => <ValidateRow key={request.id} request={request} isDirty={!!dirty[request.id]} onChange={(status) => { onChange(request, status) }} />)}
      </tbody>
    </table>
  </>;
}
