export default function OrderRow({
  order: {
    id,
    shop_id,
    created_at,
    order_reference,
    payment_status,
    shipping_status,
    currency,
    amount,
  },
  ...props
}) {
  return (
    <>
      <tr
        className="text-left text-xs whitespace-no-wrap cursor-pointer hover:bg-gray-200"
        {...props}
      >
        <th className="px-6 py-2">{id}</th>
        <td className="px-6 py-2">{shop_id}</td>
        <td className="px-6 py-2">{created_at}</td>
        <td className="px-6 py-2">{order_reference}</td>
        <td className="px-6 py-2">{payment_status}</td>
        <td className="px-6 py-2">{shipping_status}</td>
        <td className="px-6 py-2">{currency}</td>
        <td className="px-6 py-2 text-right">{amount.toFixed(2)}</td>
      </tr>
    </>
  );
}
