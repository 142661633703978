import ItemRow from "./ItemRow";

export default function ItemsTable({ items }) {
  return <>
    <table className="border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
      <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
        <tr>
          <th className="px-6 py-2">Product Id</th>
          <th className="px-6 py-2">Currency</th>
          <th className="px-6 py-2">Unit Price</th>
          <th className="px-6 py-2">Quantity</th>
          <th className="px-6 py-2">Line Total</th>
          <th className="px-6 py-2">Status</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {items.map((item) => <ItemRow key={item.id} item={item} />)}
      </tbody>
    </table>
  </>;
}
