export default function Base({ children }) {
  return <>
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
      <div role="dialog" className="relative bg-white z-50 w-full mx-4 md:max-w-md rounded-lg px-4 py-2">
        {children}
      </div>
    </div>
  </>;
}
