import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APIContext } from 'contexts/APIContext.js';
import { logoutUser, loginUser, selectAuth } from 'redux/auth';

const useAPIAuth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const client = useContext(APIContext);
    const {token} = useSelector(selectAuth);

    const login = useCallback(({ email, password }) => {
        dispatch(loginUser(client, email, password))
    }, [dispatch, client]);
    
    const logout = useCallback(() => {
        dispatch(logoutUser(client));
        history.push('/auth/login');
    }, [dispatch, client, history]);
    
    return { token, login, logout };
}

export default useAPIAuth;
