import React from "react";
import ProductRow from "./ProductRow";
import { useHistory } from "react-router-dom";

export default function ProductsTable(props) {
  const history = useHistory();
  const { products } = props;
  return (
    <>
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
          <tr className="border-b border-solid text-xs uppercase whitespace-no-wrap font-semibold text-left bg-gray-500 text-gray-200 border-gray-200">
            <th className="px-6 py-2">Shop Code</th>
            <th className="px-6 py-2">Serial #</th>
            <th className="px-6 py-2">Name</th>
            <th className="px-6 py-2">Short Desc.</th>
            <th className="px-6 py-2">Currency</th>
            <th className="px-6 py-2">Price</th>
            <th className="px-6 py-2">Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {products &&
            products.map((product) => (
              <ProductRow
                key={product.id}
                product={product}
                onClick={() => {
                  history.push(
                    `shops/${product.shop_id}/products/${product.id}`
                  );
                }}
              />
            ))}
        </tbody>
      </table>
    </>
  );
}
