import React, { createContext } from "react";
import client from 'common/api/client';

export const APIContext = createContext();

export function APIProvider(props) {
    return (
        <APIContext.Provider value={client}>
            {props.children}
        </APIContext.Provider>
    )
}
