import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Sidebar from "./Sidebar.jsx";
import Footer from "./Footer.jsx";

import NotFound from "views/admin/NotFound/NotFound.jsx";
import Shops from "views/admin/Shops/Shops.jsx";
import Shop from "views/admin/Shops/Shop.jsx";
import Orders from "views/admin/Orders/Orders.jsx";
import Order from "views/admin/Orders/Order.jsx";
import Refunds from "views/admin/Refunds/Refunds.jsx";
import PORequests from "views/admin/Payouts/Requests.jsx";
import POValidate from "views/admin/Payouts/Validate.jsx";
import POTransfers from "views/admin/Transfers/Transfers.jsx";
import POTransfer from "views/admin/Transfers/Transfer.jsx";
import Products from "views/admin/Products/Products.jsx";
import Product from "views/admin/Products/Product.jsx";
import SpecialPayments from "views/admin/Shops/Shop/SpecialPayments.jsx";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64">
        <main className="px-2 md:px-8 my-6 w-full">
          <Switch>
            <Redirect from="/admin" to="/admin/shops" exact />
            <Route path="/admin/shops" exact component={Shops} />
            <Route path="/admin/shop/:id" component={Shop} />
            <Route path="/admin/orders" exact component={Orders} />
            <Route path="/admin/order/:id" exact component={Order} />
            <Route path="/admin/refunds" exact component={Refunds} />
            <Route path="/admin/products" exact component={Products} />
            <Route
              path="/admin/shops/:shopId/products/:id"
              exact
              component={Product}
            />

            <Route
              path="/admin/specialPayments"
              exact
              component={SpecialPayments}
            />

            <Route
              path="/admin/payouts/requests"
              exact
              component={PORequests}
            />
            <Route
              path="/admin/payouts/validate/:shop_id"
              exact
              component={POValidate}
            />
            <Route
              path="/admin/payouts/transfers"
              exact
              component={POTransfers}
            />
            <Route
              path="/admin/payouts/transfers/:id"
              exact
              component={POTransfer}
            />
            <Route
              path="/admin/specialPayments/:id"
              exact
              component={POTransfer}
            />
            <Route component={NotFound} />
          </Switch>
        </main>
        <Footer />
      </div>
    </>
  );
}
