import Button from "components/Buttons/Button";
import Base from "./Base";

export default function Confirm({ title = "Are you sure?", children, onConfirm, onCancel }) {
  return <>
    <Base>
      <h1 className="-mx-4 px-4 mb-2 border-b border-solid border-gray-200 text-xl font-semibold">{title}</h1>
      <button className="absolute top-2 right-4 text-gray-500" onClick={onCancel}><i className="fas fa-times"></i></button>
      {children}
      <div className="-mx-4 px-4 pt-2 mt-2 border-t border-solid border-gray-200 text-right">
        <Button className="bg-blue-500 text-gray-100 ring-blue-500 mr-2" onClick={onConfirm}>Confirm</Button>
        <Button className="bg-gray-500 text-gray-100 ring-gray-500" onClick={onCancel}>Cancel</Button>
      </div>
    </Base>
  </>;
}
