import React from "react";
import ShippingMethodsTable from "./ShippingMethods/ShippingMethodsTable";
import useAPIData from "hooks/useAPIData";

export default function ShippingMethods(props) {
  const { data: shippingMethods } = useAPIData(
    `shops/${props.shop.id}/shipping-methods`
  );
  return (
    <>
      {shippingMethods && (
        <ShippingMethodsTable {...props} shippingMethods={shippingMethods} />
      )}
    </>
  );
}
