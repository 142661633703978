import FieldRow from "./FieldRow.jsx";
import EditModal from "./EditModal.jsx";
import { useState } from "react";

export default function FieldsTable({
  fields,
  onApprove,
  onReject,
  shopId,
  onUpdated,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editFields, setEditFields] = useState([]);
  return (
    <>
      {isEditing && (
        <EditModal
          onUpdated={onUpdated}
          setEditFields={setEditFields}
          editFields={editFields}
          onEdit={setIsEditing}
          shopId={shopId}
        />
      )}
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-100 border-gray-200">
          <tr>
            <th className="px-6 py-2">Type</th>
            <th className="px-6 py-2">Value</th>
            <th className="px-6 py-2">Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {fields.map((field) => (
            <FieldRow
              key={field.key}
              field={field}
              onApprove={onApprove}
              onReject={onReject}
              onEdit={setIsEditing}
              setEditFields={setEditFields}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
