import RefundRow from "./RefundRow.jsx";

export default function RefundsTable({ refunds, onApprove, onReject }) {
  return (
    <>
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
          <tr>
            <th className="px-6 py-2">Shop ID</th>
            <th className="px-6 py-2">Order Ref.</th>
            <th className="px-6 py-2">Txn ID</th>
            <th className="px-6 py-2">Currnecy</th>
            <th className="px-6 py-2 text-right">Amount</th>
            <th className="px-6 py-2">Refund Time</th>
            <th className="px-6 py-2">Remark</th>
            <th className="px-6 py-2">Status</th>
            <th className="px-6 py-2"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {refunds.map((refund) => (
            <RefundRow
              key={refund.id}
              refund={refund}
              onApprove={() => onApprove(refund)}
              onReject={() => onReject(refund)}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
