import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Auth from "layouts/Auth";
import Admin from "layouts/Admin/Admin";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Redirect to="/auth/login" />
      </Switch>
      <ToastContainer position="top-right" closeOnClick />
    </BrowserRouter>
  );
}

export default App;
