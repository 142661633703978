import React from "react";

export default function ShopData(props) {
  const { seller, balance } = props.data;
  return (
    <div
      className="grid gap-x-4 gap-y-2 my-6"
      style={{ gridTemplateColumns: "max-content max-content" }}
    >
      <span className="text-right font-semibold">Seller’s Email</span>
      <span>{seller.email}</span>
      <span className="text-right font-semibold">Account Type</span>
      <span>{seller.type}</span>
      <span className="text-right font-semibold">Account Status</span>
      <span>{seller.shop_status}</span>
      <span className="text-right font-semibold">Balance Status</span>
      <span>{seller.balance_status}</span>

      <span className="text-right font-semibold mt-6">Available Balance</span>
      <span className="mt-6">
        {parseFloat(balance.available_balance).toFixed(2)}
      </span>
      <span className="text-right font-semibold">Pending Balance</span>
      <span>{parseFloat(balance.pending_balance).toFixed(2)}</span>
      <span className="text-right font-semibold">Pending Transfer Amount</span>
      <span>{parseFloat(balance.transferring_balance).toFixed(2)}</span>
      <span className="text-right font-semibold">Rolling Reserve Amount</span>
      <span>{parseFloat(balance.rolling_reserve_amount).toFixed(2)}</span>
    </div>
  );
}
