import React from "react";
import { useParams } from "react-router-dom";
import useAPIData from "hooks/useAPIData";
import ProductInfo from "./Product/ProductInfo";

export default function Product() {
  const { id, shopId } = useParams();
  const { data: product } = useAPIData(`/shops/${shopId}/products/${id}`);
  console.log(product);
  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        Product Details
      </h1>
      {product && <ProductInfo product={product} />}
    </>
  );
}
