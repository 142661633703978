export default function FieldRow({
  field,
  onApprove,
  onReject,
  onEdit,
  setEditFields,
}) {
  const editFields = field.fields;
  return (
    <>
      <tr>
        <td className="px-6 py-2">{field.name}</td>
        <td className="px-6 py-2 whitespace-pre">
          {field.value}
          {field.key !== "br" && (
            <i
              onClick={() => {
                onEdit(true);
                setEditFields(editFields);
                console.log(editFields);
              }}
              className="fas fa-pencil-alt cursor-pointer"
            ></i>
          )}
        </td>
        <td className="px-6">
          <button
            className={`text-xl focus:outline-none mr-2 ${
              field.status === "APPROVED"
                ? "text-green-500"
                : "text-gray-200 hover:text-green-200 focus:text-green-200"
            }`}
            onClick={() => onApprove(field)}
          >
            <i className="fas fa-check"></i>
          </button>
          <button
            className={`text-xl focus:outline-none ${
              field.status === "REJECTED"
                ? "text-red-500"
                : "text-gray-200 hover:text-red-200 focus:text-red-200"
            }`}
            onClick={() => onReject(field)}
          >
            <i className="fas fa-times"></i>
          </button>
        </td>
      </tr>
    </>
  );
}
