import { useHistory } from "react-router-dom";

import useAPIData from "hooks/useAPIData";
import RequestsTable from "./Requests/RequestsTable.jsx";

export default function Requests() {
  const history = useHistory();
  const { data: requests = [] } = useAPIData("/payout/requests");

  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        Payout Requests
      </h1>
      <RequestsTable
        requests={requests}
        onSelect={(request) => {
          history.push(`validate/${request.shop_id}`);
        }}
      />
    </>
  );
}
