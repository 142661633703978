import React from "react";

export default function ShippingMethodsRow(props) {
  const { shippingMethod } = props;
  const currency = "HKD";
  const getPrice = (prices) => {
    return prices[0]["price"];
  };
  const getTranslation = (translations) => {
    return translations[0]["name"];
  };
  return (
    <>
      <tr className="text-left text-xs whitespace-no-wrap cursor-pointer hover:bg-gray-200">
        <td className="px-6 py-2">
          {getTranslation(shippingMethod.translations)}
        </td>
        <td className="px-6 py-2">{currency}</td>
        <td className="px-6 py-2">{getPrice(shippingMethod.prices)}</td>
        <td className="px-6 py-2">{shippingMethod.status}</td>
      </tr>
    </>
  );
}
