import React from "react";
import useAPI from "hooks/useAPI";
import useAlertWrapper from "hooks/useAlertWrapper";
import { useState } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function EditModal(props) {
  const { request } = useAPI();
  const saveRequest = useAlertWrapper(request, {
    success: { message: "Created" },
    pending: { message: "Saving..." },
    error: { message: "Error while saving" },
  });
  const { isUpdated, onEdit, shopId } = props;
  const [editFields, setEditFields] = useState({
    type: "SETTLEMENT",
    currency: "HKD",
    amount: 0,
    target: "AVAILABLE_BALANCE",
    paid_at: dayjs().format("YYYY-MM-DD"),
    remark: "",
  });

  const handleChange = (e) => {
    setEditFields({
      ...editFields,
      [e.target.name]: e.target.value,
    });
  };

  const save = async () => {
    console.log(editFields);
    if (editFields.type !== "OTHERS" && editFields.amount > 0) {
      toast("Please enter a negative (-) amount.", {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      });
      return;
    }

    await saveRequest({
      method: "post",
      url: `/special-payments/${shopId}`,
      data: {
        ...editFields,
        paid_at: dayjs(editFields.paid_at).format(
          `YYYY-MM-DD [${dayjs().format("HH:mm:DD")}]`
        ),
      },
    });
    onEdit(false);
    isUpdated();
  };
  return (
    <>
      <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white rounded shadow-lg w-1/3">
          <div className="border-b px-4 py-2 flex justify-between items-center">
            <h3 className="font-semibold text-lg">Create Special Payment</h3>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap mx-auto mb-6 justify-center">
              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Type
                </label>
                <div className="flex-shrink w-full inline-block relative">
                  <select
                    name="type"
                    onChange={handleChange}
                    className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
                  >
                    <option value="SETTLEMENT">Settlement (-)</option>
                    <option value="BANK_CHARGES">Bank Charges (-)</option>
                    <option value="CHARGEBACK">Chargeback (-)</option>
                    <option value="OTHERS">Others (+/-)</option>
                  </select>
                  <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Currency
                </label>
                <div className="flex-shrink w-full inline-block relative">
                  <select
                    onChange={handleChange}
                    name="currency"
                    className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
                  >
                    <option value="HKD">HKD</option>
                  </select>
                  <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Amount
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                  type="number"
                  name="amount"
                  step=".01"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Target
                </label>
                <div className="flex-shrink w-full inline-block relative">
                  <select
                    name="target"
                    onChange={handleChange}
                    className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
                  >
                    <option value="AVAILABLE_BALANCE">Available Balance</option>
                  </select>
                  <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Paid At
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                  type="text"
                  value={dayjs().format("YYYY-MM-DD")}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="w-full md:w-full px-3 mb-2 mt-2">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Remark
                </label>
                <textarea
                  onChange={handleChange}
                  className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner h-40 rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                  name="remark"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center w-100 border-t p-3">
            <button
              onClick={() => {
                onEdit(false);
              }}
              className="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal"
            >
              Cancel
            </button>
            <button
              onClick={save}
              className="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-white"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
