import { useHistory } from "react-router-dom";

import useAPIData from "hooks/useAPIData";
import OrdersTable from "./Orders/OrdersTable";

export default function Orders() {
  const history = useHistory();
  const { data: orders = [] } = useAPIData("/orders");
  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">Orders</h1>
      <OrdersTable
        orders={orders}
        onSelect={(order) => {
          history.push(`order/${order.id}`);
        }}
      />
    </>
  );
}
