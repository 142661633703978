import OrderRow from "./OrderRow.jsx";

export default function OrdersTable({ orders, onSelect }) {
  return <>
    <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
      <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
        <tr className="border-b border-solid text-xs uppercase whitespace-no-wrap font-semibold text-left bg-gray-500 text-gray-200 border-gray-200">
          <th className="px-6 py-2">Order ID</th>
          <th className="px-6 py-2">Shop ID</th>
          <th className="px-6 py-2">Order Time</th>
          <th className="px-6 py-2">Reference</th>
          <th className="px-6 py-2">Payment Status</th>
          <th className="px-6 py-2">Shipping Status</th>
          <th className="px-6 py-2">Currency</th>
          <th className="px-6 py-2">Amount</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {orders.map((order) => <OrderRow key={order.id} order={order} onClick={() => onSelect(order)} />)}
      </tbody>
    </table>
  </>;
}
