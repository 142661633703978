import Button from "components/Buttons/Button.jsx";
import React, { useState, useEffect } from "react";
import useAPI from "hooks/useAPI";
import useAlertWrapper from "hooks/useAlertWrapper";

export default function Shop({
  shop: {
    id,
    shop_name,
    code,
    email,
    type,
    status,
    approval_status,
    subscription_until,
    banner,
    logo,
    translation,
    seller,
    phone,
    facebook_url,
    instagram_url,
  },
}) {
  console.log(translation);
  const { request } = useAPI();
  const [shopStatus, setShopStatus] = useState("ACTIVE");
  useEffect(() => {
    setShopStatus(status);
  }, [status]);
  const handleChange = (e) => {
    setShopStatus(e.target.value);
  };
  const updateRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Update failed." },
    success: { message: "Saved" },
  });
  const handleSave = async () => {
    await updateRequest({
      method: "post",
      url: `/shops/${id}/update`,
      data: { status: shopStatus },
    });
  };
  const getName = (translation) => {
    if (translation) {
      return translation.name;
    }
    return "";
  };
  return (
    <>
      <div
        className="grid gap-x-4 gap-y-2"
        style={{ gridTemplateColumns: "max-content max-content" }}
      >
        <span className="text-right font-semibold">Email</span>
        <span>{seller.email}</span>
        <span className="text-right font-semibold">Country</span>
        <span>{seller.country}</span>
        <span className="text-right font-semibold">Banner</span>
        {banner ? (
          <img
            className="w-48"
            src={`${process.env.REACT_APP_STOREFRONT}/${banner}`}
            alt=""
          />
        ) : (
          <span>N/A</span>
        )}
        <span className="text-right font-semibold">Logo</span>
        {logo ? (
          <img
            className="w-48"
            src={`${process.env.REACT_APP_STOREFRONT}/${logo}`}
            alt=""
          />
        ) : (
          <span>N/A</span>
        )}
        <span className="text-right font-semibold">Code</span>
        <span>{code}</span>
        <span className="text-right font-semibold">Shop Name</span>
        <span>{getName(translation)}</span>
        <span className="text-right font-semibold">Contact Number</span>
        <span>{phone}</span>
        <span className="text-right font-semibold">Facebook ID</span>
        <span>{facebook_url}</span>
        <span className="text-right font-semibold">Instagram ID</span>
        <span>{instagram_url}</span>
        <span className="text-right font-semibold">Shop Link</span>
        <a
          className="text-blue-500 hover:text-blue-700"
          href={`${process.env.REACT_APP_STOREFRONT}/${code}?preview=true`}
          target="_blank"
          rel="noreferrer"
        >
          {`${process.env.REACT_APP_STOREFRONT}/${code}`}
        </a>
        <span className="text-right font-semibold">Type</span>
        <span>{seller.type}</span>
        <span className="text-right font-semibold">Approval Status</span>
        <span>{approval_status}</span>
        <span className="text-right font-semibold">Expiry</span>
        <span>{subscription_until}</span>
        <span className="text-right font-semibold">Shop Status</span>
        <select
          name="status"
          className="form-select mt-1 block w-full border border-gray-400"
          onChange={handleChange}
          value={shopStatus}
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="SUSPENDED">SUSPENDED</option>
        </select>
      </div>
      <div className="-mx-4 px-4 pt-2 mt-2 text-right">
        <Button
          onClick={handleSave}
          className="bg-blue-500 text-gray-100 ring-blue-500 mr-2 w-32"
        >
          Save
        </Button>
      </div>
    </>
  );
}
