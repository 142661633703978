import Button from "components/Buttons/Button.jsx";

export default function RefundRow({
  refund,
  refund: {
    shop_id,
    order_reference,
    transaction_id,
    currency,
    refund_amount,
    created_at,
    approval_status,
    remark,
  },
  onApprove,
  onReject,
}) {
  return (
    <>
      <tr className="hover:bg-gray-200">
        <th className="px-6 py-2">{shop_id}</th>
        <td className="px-6 py-2">{order_reference}</td>
        <td className="px-6 py-2">{transaction_id}</td>
        <td className="px-6 py-2">{currency}</td>
        <td className="px-6 py-2 text-right">{refund_amount.toFixed(2)}</td>
        <td className="px-6 py-2">{created_at}</td>
        <td className="px-6 py-2 whitespace-pre-line">{remark}</td>
        <td className="px-6 py-2">{approval_status}</td>
        <td className="px-6">
          {approval_status === "PENDING" ? (
            <>
              <Button
                className="bg-green-500 text-gray-100 ring-green-500 mr-2"
                onClick={() => onApprove(refund)}
              >
                Approve
              </Button>
              <Button
                className="bg-red-500 text-gray-100 ring-red-500"
                onClick={() => onReject(refund)}
              >
                Reject
              </Button>
            </>
          ) : null}
        </td>
      </tr>
    </>
  );
}
