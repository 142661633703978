import React, {useState} from "react";
import SpecialPaymentsRow from "./SpecialPaymentsRow.jsx";
import EditSpecialPayments from "./EditSpecialPayments.jsx";

export default function ShippingMethodsTable(props) {
  const {isUpdated, specialPayments} = props;
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {isEditing && (
        <EditSpecialPayments
          onEdit={setIsEditing}
          shopId={props.shop.id}
          isUpdated={isUpdated}
        />
      )}
      <div className="text-right mb-2">
        <button className=" px-4 py-1 rounded-lg
      focus:outline-none focus:ring ring-opacity-30
      disabled:text-gray-800 disabled:bg-gray-200 disabled:opacity-70 disabled:cursor-not-allowed
      bg-blue-500 text-gray-100 ring-blue-500 mr-2 w-32 "
                onClick={() => {
                  setIsEditing(true);
                }}
        >Create
        </button>
      </div>
      <table
        className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
        <tr
          className="border-b border-solid text-xs uppercase whitespace-no-wrap font-semibold text-left bg-gray-500 text-gray-200 border-gray-200">
          <th className="px-6 py-2">Shop ID</th>
          <th className="px-6 py-2">Type</th>
          <th className="px-6 py-2">Amount</th>
          <th className="px-6 py-2">Currency</th>
          <th className="px-6 py-2">Target</th>
          <th className="px-6 py-2">Remark</th>
          <th className="px-6 py-2">Created At</th>
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
        {specialPayments &&
        specialPayments.map((specialPayment) => (
          <SpecialPaymentsRow
            key={specialPayment.id}
            specialPayment={specialPayment}
          />
        ))}
        </tbody>
      </table>
    </>
  );
}
