export default function ItemRow({
  item: { product_id, currency, price, quantity, amount, status },
  ...props
}) {
  return (
    <>
      <tr className="hover:bg-gray-200" {...props}>
        <th className="px-6 py-2">{product_id}</th>
        <td className="px-6 py-2">{currency}</td>
        <td className="px-6 py-2 text-right">{price.toFixed(2)}</td>
        <td className="px-6 py-2">{quantity}</td>
        <td className="px-6 py-2 text-right">
          {(price * quantity).toFixed(2)}
        </td>
        <td className="px-6 py-2">{status}</td>
      </tr>
    </>
  );
}
