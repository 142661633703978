import dayjs from "dayjs";

export default function SpecialPaymentsRow({ specialPayment: { shop_id,type, amount ,currency ,remark , target ,status , created_at }, onSelect }) {
  return <>
    <tr className="cursor-pointer hover:bg-gray-200" onClick={onSelect}>
      <th className="px-6 py-2">{shop_id}</th>
      <td className="px-6 py-2">{type}</td>
      <td className="px-6 py-2">{amount}</td>
      <td className="px-6 py-2">{currency}</td>
      <td className="px-6 py-2">{target}</td>
      <td className="px-6 py-2">{remark}</td>
      <td className="px-6 py-2">{dayjs(created_at).format("YYYY-MM-DD hh:mm:ss")}</td>
    </tr>
  </>;
}
