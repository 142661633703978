import { useCallback, useRef } from 'react';
import {toast} from 'react-toastify';

const upsertAlert = (toastId, {message, type, autoClose = 2000}) => {
    if(toastId.current){
        toastId.current = toast.update(toastId.current, {
            render: message,
            type: type,
            autoClose: autoClose
        });
    }else{
        toast(message, {
            type: type,
            autoClose: autoClose
        })
    }
    return toastId.current
}

const asyncAlert = async (promise, { pending, success, error, toastId }) => {
    if (pending) {
        toastId.current = toast.info(pending.message, {
            autoClose: false
        });
    }
    try {
        await promise;
        if (success) {
            setTimeout(() => { 
                toastId.current = upsertAlert(toastId, {
                    message: success.message,
                    type: toast.TYPE.SUCCESS
                });
            }, 500);
        } else {
            setTimeout(() => toast.dismiss(toastId.current), 500);
        }
    } catch(err) {
        if (error) {
            setTimeout(() => { 
                toastId.current = upsertAlert(toastId, {
                    message: `${error.message} - ${err.message}`  ,
                    type: toast.TYPE.ERROR
                });
            }, 500);
        } else {
            setTimeout(() => toast.dismiss(toastId.current), 500);
        }
    }
}

const useAlertWrapper = (fn, configs) => {
    const toastId = useRef(null);
    return useCallback(function (...args) {
        const promise = fn.call(this, ...args);
        asyncAlert(promise, {...configs, toastId});
        return promise;
    }, [fn, configs]);
}

export default useAlertWrapper;