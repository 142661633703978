import React from "react";

const paymentMethods = {
  credit_card: "Credit Card",
  payme: "PayMe",
};

export default function OrderRow(props) {
  const { order } = props;
  return (
    <tr
      onClick={() => {}}
      className="text-left text-xs whitespace-no-wrap cursor-pointer hover:bg-gray-200"
    >
      <td className="px-6 py-2">{order.order_reference}</td>
      <td className="px-6 py-2">{order.shop_code}</td>
      <th className="px-6 py-2">{order.currency}</th>
      <th className="px-6 py-2">{parseFloat(order.amount).toFixed(2)}</th>
      <td className="px-6 py-2">{paymentMethods[order.payment_method]}</td>
      <td className="px-6 py-2">{order.email}</td>
      <td className="px-6 py-2">{order.created_at}</td>
      <td className="px-6 py-2">{order.payment_status}</td>
      <td className="px-6 py-2">{order.shipping_status}</td>
    </tr>
  );
}
