import React from "react";
import useAPIData from "hooks/useAPIData";
import { useParams } from "react-router-dom";

export default function SettlementInfo() {
  const { id } = useParams();
  const { data } = useAPIData(`/shops/${id}/settlementInfo`);
  let balance;
  if (data?.balance) {
    balance = data.balance;
  }
  console.log(data);
  return (
    <>
      <div className="mx-auto py-4">
        <div className="flex flex-row flex-wrap -mx-2">
          {balance ? (
            <>
              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Total Sales
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                HKD {parseFloat(balance.sales_amount).toFixed(2)}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Total Payouts
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                HKD {parseFloat(balance.transferred_balance).toFixed(2)}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Total Reserve
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                HKD {parseFloat(balance.rolling_reserve_amount).toFixed(2)}
              </div>

              <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
                Total Refund
              </div>
              <div className="w-full sm:w-5/6 mb-4 px-2">
                HKD {Math.abs(parseFloat(balance.refund_amount)).toFixed(2)}
              </div>
            </>
          ) : null}

          {data ? (
            <>
              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Bank Name
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">{data.bank_name}</div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Bank Code
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">{data.bank_code}</div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Account Number
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {data.account_number}
              </div>

              <div className="w-full sm:w-1/6 mb-4 px-2 font-semibold">
                Account Holder Name
              </div>
              <div className="w-full sm:w-5/6 mb-4 px-2">
                {data.account_holder}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Seller’s Email
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {data.seller.email}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Seller’s Type
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {data.seller.type}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Available Balance
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {parseFloat(balance.available_balance).toFixed(2)}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Pending Balance
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {parseFloat(balance.pending_balance).toFixed(2)}
              </div>

              <div className="w-full sm:w-1/6 mb-2 px-2 font-semibold">
                Pending Refund
              </div>
              <div className="w-full sm:w-5/6 mb-2 px-2">
                {parseFloat(balance.pending_refund).toFixed(2)}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
