import { useState } from "react";
import { Redirect } from "react-router-dom";

import useAPIAuth from "hooks/useAPIAuth";

export default function Login() {
  const { token, login } = useAPIAuth();

  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (token) {
    return <Redirect to="/admin" />;
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();
    try {
      await login({ email, password });
    } catch (err) {
      setError(err.message ?? "Unknown Error");
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="rounded-t mb-0 px-6 py-6">
          <div className="text-center mb-3">
            <h1 className="font-bold text-center text-2xl my-5">
              Mazetag Shop
            </h1>
          </div>
        </div>
        {error ? (
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div className="alert flex flex-row items-center bg-red-200 p-2 rounded border-b-2 border-red-300">
              <div className="alert-icon flex items-center bg-red-100 border-2 border-red-500 justify-center h-10 w-10 flex-shrink-0 rounded-full">
                <span className="text-red-500">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className="h-6 w-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="alert-content ml-4">
                <div className="alert-description text-sm text-red-600">
                  {error}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={submitHandler}>
            <div className="relative w-full mt-3 mb-9">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                onChange={(ev) => setEmail(ev.target.value)}
                type="email"
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Email"
              />
            </div>

            <div className="relative w-full my-9">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Password
              </label>
              <input
                type="password"
                onChange={(ev) => setPassword(ev.target.value)}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="Password"
              />
            </div>
            <div className="text-center mt-3 pb-9">
              <button
                className="mt-9 text-lg font-semibold w-full text-white rounded-lg px-6 py-3 block shadow-xl bg-blue-500 hover:bg-blue-600 focus:bg-blue-700"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
