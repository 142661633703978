import { NavLink, Route, Switch, useParams } from "react-router-dom";

import useAPIData from "hooks/useAPIData";

import ShopTab from "./Shop/Shop.jsx";
import DocumentsTab from "./Shop/Documents.jsx";
import SettingsTab from "./Shop/Settings.jsx";
import ProductsTab from "./Shop/Products.jsx";
import Product from "./Shop/Products/Product/Product.jsx";
import OrderTab from "./Shop/Orders.jsx";
import BankInfoTab from "./Shop/BankInfo.jsx";
import ShippingMethodsTab from "./Shop/ShippingMethods.jsx";
import SpecialPaymentsTab from "./Shop/SpecialPayments.jsx";
import SettlementInfo from "./Shop/SettlementInfo.jsx";

export default function Shop() {
  const { id } = useParams();
  const { data: shop } = useAPIData(`/shops/${id}`);
  return shop === undefined ? null : (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        {shop.shop_name}
      </h1>
      <nav className="-mx-2 md:-mx-8 mb-4">
        <ul className="mx-1 md:mx-4 px-2 border-b border-gray-300 flex flex-row">
          <li className="px-2 cursor-pointer">
            <NavLink to={`/admin/shop/${id}`} activeClassName="font-bold" exact>
              Shop
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/documents`}
              activeClassName="font-bold"
              exact
            >
              Documents
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/settings`}
              activeClassName="font-bold"
              exact
            >
              Settings
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/products`}
              activeClassName="font-bold"
              isActive={() => window.location.pathname.match(/products/)}
              exact
            >
              Products
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/orders`}
              activeClassName="font-bold"
              isActive={() => window.location.pathname.match(/orders/)}
              exact
            >
              Orders
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/bankInfo`}
              activeClassName="font-bold"
              exact
            >
              Bank Info
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/shippingMethods`}
              activeClassName="font-bold"
              exact
            >
              Shipping Methods
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/specialPayments`}
              activeClassName="font-bold"
              exact
            >
              Special Payments
            </NavLink>
          </li>
          <li className="px-2 cursor-pointer">
            <NavLink
              to={`/admin/shop/${id}/settlementInfo`}
              activeClassName="font-bold"
              exact
            >
              Settlement Info
            </NavLink>
          </li>
        </ul>
      </nav>
      <section>
        <Switch>
          <Route path="/admin/shop/:id" exact>
            <ShopTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/documents" exact>
            <DocumentsTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/settings" exact>
            <SettingsTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/products/:productId" exact>
            <Product shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/products" exact>
            <ProductsTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/orders" exact>
            <OrderTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/bankInfo" exact>
            <BankInfoTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/shippingMethods" exact>
            <ShippingMethodsTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/specialPayments" exact>
            <SpecialPaymentsTab shop={shop} />
          </Route>
          <Route path="/admin/shop/:id/settlementInfo" exact>
            <SettlementInfo shop={shop} />
          </Route>
        </Switch>
      </section>
    </>
  );
}
