export default function Button({ className, ...props }) {
  return <button
    {...props}
    className={`
      px-4 py-1 rounded-lg
      focus:outline-none focus:ring ring-opacity-30
      disabled:text-gray-800 disabled:bg-gray-200 disabled:opacity-70 disabled:cursor-not-allowed
      ${className ?? ''}
    `}
  ></button>
}
