import OrdersTable from "./Orders/OrdersTable.jsx";
import React, { useEffect, useState } from "react";
import useAPIData from "hooks/useAPIData";

export default function Orders(props) {
  const [orders, setOrders] = useState([]);
  const { data: orderData } = useAPIData(`shops/${props.shop.id}/orders`);
  useEffect(() => {
    setOrders(orderData);
  }, [orderData]);
  return (
    <>
      <OrdersTable {...props} orders={orders} />
    </>
  );
}
