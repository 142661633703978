import React, { useEffect, useState } from "react";

const paymentMethods = {
  payme: "PayMe",
  credit_card: "Credit Card (Visa / Master)",
};

export default function PaymentMethod({ method, onChange }) {
  const [methodData, setMethodData] = useState(method);

  useEffect(() => {
    onChange(methodData);
  }, [
    methodData.mdr,
    methodData.pay_days_after,
    methodData.rolling_reserve_rate,
    methodData.rolling_reserve_days,
    methodData.status,
    methodData,
    onChange,
  ]);

  const handleChange = (e) =>
    setMethodData((prevMethodData) => {
      return { ...prevMethodData, [e.target.name]: e.target.value };
    });

  return (
    <>
      <div className="personal w-full border-t border-gray-400 pt-4">
        <h2 className="text-2xl text-gray-900">
          {paymentMethods[method.payment_method]}
        </h2>
        <div className="w-full md:w-full px-3 mb-6 mt-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            MDR (%)
          </label>
          <input
            className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
            type="number"
            name="mdr"
            onChange={handleChange}
            value={methodData.mdr}
            min="0"
            step=".01"
            required
          />
        </div>
        <div className="w-full md:w-full px-3 mb-6 mt-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Pay Days After (T+X)
          </label>
          <input
            className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
            type="number"
            name="pay_days_after"
            onChange={handleChange}
            value={methodData.pay_days_after}
            min="0"
            required
          />
        </div>
        <div className="w-full md:w-full px-3 mb-6">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Rolling Reserve Rate (%)
          </label>
          <input
            className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
            type="number"
            name="rolling_reserve_rate"
            onChange={handleChange}
            value={methodData.rolling_reserve_rate}
            min="0"
            step=".01"
            required
          />
        </div>
        <div className="w-full md:w-full px-3 mb-6">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Rolling Reserve Days
          </label>
          <input
            className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
            type="number"
            name="rolling_reserve_days"
            onChange={handleChange}
            value={methodData.rolling_reserve_days}
            min="0"
            step=".01"
            required
          />
        </div>
        <div className="w-full md:w-full px-3 mb-6">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Status
          </label>
          <div className="flex-shrink w-full inline-block relative">
            <select
              name="status"
              className="block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded"
              onChange={handleChange}
              value={methodData.status}
            >
              <option value="ACTIVE">ACTIVE</option>
              <option value="SUSPENDED">SUSPENDED</option>
            </select>
            <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
