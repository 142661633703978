export default function ValidateRow({ isDirty, request: { id, shop_id, created_at, currency, amount, status }, onChange }) {
  return <>
    <tr className="hover:bg-gray-200">
      <th className="px-6 py-2">{id}</th>
      <th className="px-6 py-2">{shop_id}</th>
      <td className="px-6 py-2">{created_at}</td>
      <td className="px-6 py-2">{currency}</td>
      <td className="px-6 py-2 text-right">{amount}</td>
      <td className={`px-6 ${status === 'PENDING' || isDirty ? 'py-1' : 'py-2'}`}>
        {
          status === 'PENDING' || isDirty
            // TODO: Extract select component
            ? <select
              onChange={(ev) => { onChange(ev.target.value) }}
              className="border px-1 border-gray-500 rounded focus:outline-none focus:ring ring-opacity-30"
            >
              <option value="PENDING">PENDING</option>
              <option value="APPROVED">APPROVED</option>
              <option value="REJECTED">REJECTED</option>
            </select>
            : status
        }
      </td>
    </tr>
  </>;
}
