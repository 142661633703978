import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'views/auth/Login.jsx';

export default function Auth() {
  return <>
    <main>
      <section className="relative w-full h-full py-40 min-h-screen">
        <div className="absolute top-0 w-full h-full bg-blue-50 bg-no-repeat bg-full">
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
                <Switch>
                  <Route path="/auth/login" exact component={Login} />
                  <Redirect from="/auth" to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </>;
}
