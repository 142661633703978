import { useMutableCollection } from "common/hooks.js";
import useAPI from "hooks/useAPI";
import useAPIData from "hooks/useAPIData";
import useAlertWrapper from "hooks/useAlertWrapper";

import Button from "components/Buttons/Button.jsx";
import FieldsTable from "./Documents/FieldsTable.jsx";
import client from "common/api/client";
import { useState, useEffect } from "react";

function downloadBlob(blob) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "documents.zip";
  link.click();
}

export default function Documents({ shop: { id } }) {
  const { data = [] } = useAPIData(`/shops/${id}/documents`);
  const [docExist, setDocExist] = useState(false);
  const { data: checkDoc } = useAPIData(
    `/shops/${id}/documents/check-download`
  );
  useEffect(() => {
    if (checkDoc) {
      setDocExist(checkDoc.exist);
    }
  }, [checkDoc]);
  const {
    data: fields,
    update: updateFields,
    commit,
    dirty,
  } = useMutableCollection(data ?? [], "key");
  const { request } = useAPI();
  const approveRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Error while saving approvals" },
  });

  const onUpdated = async () => {
    const updatedData = await client.get(`/shops/${id}/documents`);
    updateFields(updatedData);
  };

  const onApprove = (field) => {
    updateFields({ key: field.key, status: "APPROVED" });
  };
  const onReject = (field) => {
    updateFields({ key: field.key, status: "REJECTED" });
  };

  const approveAll = () => {
    updateFields(
      fields.map((field) => ({ key: field.key, status: "APPROVED" }))
    );
  };

  const save = async () => {
    const data = Object.fromEntries(
      Object.entries(dirty).map(([key, { status }]) => [key, status])
    );
    await approveRequest({
      method: "post",
      url: `/shops/${id}/documents/approval`,
      data,
    });
    commit();
  };

  return (
    <>
      <div className="text-right mb-2">
        {docExist && (
          <Button
            className="bg-blue-500 text-gray-100 ring-blue-500 mr-2"
            onClick={async () => {
              const { data } = await client.get(
                `/shops/${id}/documents/download`,
                {
                  responseType: "blob",
                }
              );
              downloadBlob(data);
            }}
          >
            Download Documents
          </Button>
        )}
        <Button
          className="bg-green-500 text-gray-100 ring-green-500"
          onClick={() => {
            approveAll();
          }}
        >
          Approve All
        </Button>
      </div>
      <FieldsTable
        fields={fields}
        onUpdated={onUpdated}
        onApprove={onApprove}
        onReject={onReject}
        shopId={id}
      />
      <div className="text-right mt-2">
        <Button
          className="bg-blue-500 text-gray-100 ring-blue-500"
          disabled={dirty.size === 0}
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}
