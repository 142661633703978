import React from "react";
import ShippingMethodsRow from "./ShippingMethodsRow.jsx";

export default function ShippingMethodsTable(props) {
  const { shippingMethods } = props;
  return (
    <>
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
          <tr className="border-b border-solid text-xs uppercase whitespace-no-wrap font-semibold text-left bg-gray-500 text-gray-200 border-gray-200">
            <th className="px-6 py-2">Method Name</th>
            <th className="px-6 py-2">Currency</th>
            <th className="px-6 py-2">Price</th>
            <th className="px-6 py-2">status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {shippingMethods &&
            shippingMethods.map((shippingMethod) => (
              <ShippingMethodsRow
                key={shippingMethod.id}
                shippingMethod={shippingMethod}
                // onClick={() => {
                //   history.push(`orders/${order.id}`);
                // }}
              />
            ))}
        </tbody>
      </table>
    </>
  );
}
