import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import useAPI from "hooks/useAPI";
import useAPIData from "hooks/useAPIData";
import { useMutableCollection } from "common/hooks.js";

import Button from "components/Buttons/Button.jsx";
import ValidateTable from "./Validate/ValidateTable.jsx";
import useAlertWrapper from "hooks/useAlertWrapper";
import ShopData from "./Validate/ShopData";

export default function Validate(props) {
  const history = useHistory();
  const { shop_id } = useParams();
  const { data } = useAPIData(`/payout/shops/${shop_id}/requests`);
  // console.log(data);
  const {
    data: requests,
    update: updateRequests,
    dirty,
    commit,
  } = useMutableCollection(data ? data.requests : []);

  const { request } = useAPI();
  const approveRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Error while approving requests" },
  });
  const rejectRequest = useAlertWrapper(request, {
    pending: { message: "Saving..." },
    error: { message: "Error while rejecting requests" },
  });

  const updateStatus = (id, status) => {
    updateRequests({ id, status });
  };

  const [remark, setRemark] = useState("");
  const [rejectRemark, setRejectRemark] = useState("");

  const submit = async (ev) => {
    ev.preventDefault();
    const approvals = Object.entries(dirty)
      .filter(([, { status }]) => status === "APPROVED")
      .map(([id]) => id);
    const rejections = Object.entries(dirty)
      .filter(([, { status }]) => status === "REJECTED")
      .map(([id]) => id);
    await Promise.all([
      approvals.length > 0 &&
        approveRequest({
          method: "post",
          url: "/payout/requests/approve",
          data: { ids: approvals, remark: remark },
        }),
      rejections.length > 0 &&
        rejectRequest({
          method: "post",
          url: "/payout/requests/reject",
          data: {
            ids: rejections,
            remark: remark,
            reject_remark: rejectRemark,
          },
        }),
    ]);
    commit();
    if (approvals.length > 0 || rejections.length > 0) {
      history.push("/admin/payouts/requests");
    }
  };

  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        Validate Requests
      </h1>
      <ValidateTable
        requests={requests}
        dirty={dirty}
        onChange={({ id }, status) => {
          updateStatus(id, status);
        }}
      />

      {data && <ShopData {...props} data={data} />}

      <section className="mt-8 mb-4">
        <label className="block font-semibold mb-2 text-gray-800">Remark</label>
        <textarea
          rows="5"
          className="w-full py-1 px-2 border border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30"
          value={remark}
          onInput={(ev) => {
            setRemark(ev.target.value);
          }}
        ></textarea>
      </section>

      <section className="mb-4">
        <label className="block font-semibold mb-2 text-gray-800">
          Reject Remark
        </label>
        <textarea
          rows="5"
          className="w-full py-1 px-2 border border-gray-500 rounded focus:border-blue-500 focus:outline-none focus:ring ring-opacity-30"
          value={rejectRemark}
          onInput={(ev) => {
            setRejectRemark(ev.target.value);
          }}
        ></textarea>
      </section>

      <div className="text-right">
        <Button
          type="submit"
          className="bg-blue-500 text-gray-100 ring-blue-500 py-1 px-2"
          onClick={submit}
        >
          Validate and Hold funds
        </Button>
      </div>
    </>
  );
}
