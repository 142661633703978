import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import store from './redux/main.js';
import { APIProvider } from 'contexts/APIContext.js';

import './assets/styles/index.scss';
import App from './App.jsx';

import reportWebVitals from './reportWebVitals';

render(
    <ReduxProvider store={store}>
        <APIProvider>
            <BrowserRouter>
                <StrictMode>
                    <App />
                </StrictMode>
            </BrowserRouter>
        </APIProvider>
    </ReduxProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
