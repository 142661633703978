import React, { useEffect, useState } from "react";
import ProductsTable from "./Products/ProductsTable";
import useAPIData from "hooks/useAPIData";

export default function Products(props) {
  const [products, setProducts] = useState([]);
  const { data: productData } = useAPIData(`shops/${props.shop.id}/products`);
  useEffect(() => {
    setProducts(productData);
  }, [productData]);
  return (
    <>
      <ProductsTable {...props} products={products} />
    </>
  );
}
