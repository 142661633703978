import { useHistory } from "react-router-dom";

import useAPIData from "hooks/useAPIData";
import TransfersTable from "../Transfers/Transfers/TransfersTable.jsx";

export default function Transfers() {
  const history = useHistory();
  const transfers = useAPIData("/payout/transfers").data ?? [];

  return (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">Transfers</h1>
      <TransfersTable
        transfers={transfers}
        onSelect={(transfer) => history.push(`transfers/${transfer.id}`)}
      />
    </>
  );
}
