import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import useAPIData from "hooks/useAPIData";
import ItemsTable from "./Order/ItemsTable.jsx";

export default function Orders() {
  const { id } = useParams();
  const { data: order } = useAPIData(`/orders/${id}`);

  return order === undefined ? null : (
    <>
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">
        Order Details
      </h1>
      <div
        className="grid gap-x-4 gap-y-2"
        style={{ gridTemplateColumns: "max-content max-content" }}
      >
        <span className="text-right font-semibold">Order ID</span>
        <span>{order.id}</span>
        <span className="text-right font-semibold">Shop ID</span>
        <span>{order.shop_id}</span>
        <span className="text-right font-semibold">Order Time</span>
        <span>{dayjs(order.created_at).format("YYYY-MM-DD hh:mm:ss Z")}</span>
        <span className="text-right font-semibold">Reference</span>
        <span>{order.order_reference}</span>
        <span className="text-right font-semibold">Payment Status</span>
        <span>{order.payment_status}</span>
        <span className="text-right font-semibold">Shipping Status</span>
        <span>{order.shipping_status}</span>
        <span className="text-right font-semibold">Currency</span>
        <span>{order.currency}</span>
        <span className="text-right font-semibold">Amount</span>
        <span>{order.amount.toFixed(2)}</span>
      </div>
      <h2 className="my-4 font-semibold text-xl text-gray-800">Items</h2>
      <ItemsTable items={order.items} />
    </>
  );
}
