import React from "react";
import { useHistory } from "react-router-dom";
import OrderRow from "./OrderRow.jsx";

export default function OrdersTable(props) {
  const history = useHistory();
  const { orders } = props;
  return (
    <>
      <table className="w-full border border-solid border-gray-400 rounded-lg overflow-hidden bg-transparent shadow-lg text-left text-xs whitespace-no-wrap border-collapse">
        <thead className="uppercase bg-gray-500 text-gray-200 border-gray-200">
          <tr className="border-b border-solid text-xs uppercase whitespace-no-wrap font-semibold text-left bg-gray-500 text-gray-200 border-gray-200">
            <th className="px-6 py-2">Order #</th>
            <th className="px-6 py-2">Shop Code</th>
            <th className="px-6 py-2">Currency</th>
            <th className="px-6 py-2">Amount</th>
            <th className="px-6 py-2">Payment Method</th>
            <th className="px-6 py-2">Email</th>
            <th className="px-6 py-2">Date & Time</th>
            <th className="px-6 py-2">Payment Status</th>
            <th className="px-6 py-2">Shipping Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {orders &&
            orders.map((order) => (
              <OrderRow
                key={order.id}
                order={order}
                onClick={() => {
                  history.push(`orders/${order.id}`);
                }}
              />
            ))}
        </tbody>
      </table>
    </>
  );
}
