import { useState } from "react";

import useAPI from "hooks/useAPI";
import useAPIData from "hooks/useAPIData";
import { useMutableCollection } from "common/hooks.js";

import RefundModal from "./Refunds/RefundModal.jsx";
import RefundsTable from "./Refunds/RefundsTable.jsx";
import useAlertWrapper from "hooks/useAlertWrapper";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay.jsx";

export default function Refunds() {
  const { request } = useAPI();
  const alertRequest = useAlertWrapper(request, {
    pending: { message: "Updating..." },
    error: { message: "Error while updating refunds" },
  });

  const { data = [] } = useAPIData("/refunds");
  const { data: refunds, update: updateRefunds } = useMutableCollection(data);

  const [modalState, setModalState] = useState({
    display: null,
    action: null,
    refund: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const onApprove = (refund) => {
    setModalState({ display: "Approve", action: "APPROVED", refund });
  };
  const onReject = (refund) => {
    setModalState({ display: "Reject", action: "REJECTED", refund });
  };
  const onCancel = () => {
    setModalState({ display: null, action: null, refund: null });
  };
  const onConfirm = async (payload = {}) => {
    const {
      action,
      refund: { id },
    } = modalState;
    setModalState({ display: null, action: null, refund: null });
    setIsLoading(true);
    const { remark } = payload;
    await alertRequest({
      method: "post",
      url: `/refunds/${id}/approval`,
      data: {
        action,
        remark,
      },
    });
    setIsLoading(false);
    updateRefunds({ id, approval_status: action });
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <h1 className="mb-4 font-semibold text-2xl text-gray-800">Refunds</h1>
      <RefundsTable
        refunds={refunds}
        onApprove={onApprove}
        onReject={onReject}
      />
      {modalState.action !== null ? (
        <RefundModal
          display={modalState.display}
          refund={modalState.refund}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      ) : null}
    </>
  );
}
